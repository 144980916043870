import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  readonly israelMaskCodeLength = 2;

  readonly placeholder = '0500000000';

  readonly mask = '000 000 0000';

  readonly ukraineMaskCodeLength = 3;

  readonly israelCode = '+972';

  readonly ukraineCode = '+38';

  readonly phoneLength = 10;

  constructor() {}

  get(phoneNumber: string): string {
    if (this.isIsraelNumber(phoneNumber)) {
      return this.israelCode + phoneNumber.substring(1);
    } else if (this.isUkraineNumber(phoneNumber)) {
      return this.ukraineCode + phoneNumber;
    } else {
      return this.israelCode + phoneNumber.substring(1);
    }
  }

  removeCode(phoneNumber: string): string {
    if (!phoneNumber) {
      return phoneNumber;
    }
    phoneNumber = phoneNumber.replace('+', '');
    phoneNumber = '+' + phoneNumber;
    phoneNumber = phoneNumber.replace(this.israelCode, '0');
    phoneNumber = phoneNumber.replace(this.ukraineCode, '');
    phoneNumber = phoneNumber.replace('+', '');
    return phoneNumber;
  }

  isIsraelNumber(value: string): boolean {
    return value?.substring(0, this.israelMaskCodeLength) === '05';
  }

  isUkraineNumber(value: string): boolean {
    const code = value?.substring(0, this.ukraineMaskCodeLength);
    return (
      code === '067' ||
      code === '068' ||
      code === '096' ||
      code === '097' ||
      code === '098' ||
      code === '050' ||
      code === '066' ||
      code === '095' ||
      code === '099' ||
      code === '093' ||
      code === '063' ||
      code === '073' ||
      code === '086'
    );
  }
}
